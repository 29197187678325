import { useQuery } from 'react-query';
import { Publications } from '../model/Publications';
import { apiFetch } from '../../../../util/apiFetch';
import { Publication } from '../model/Publication';
import * as InstabilityType from '../../../../util/InstabilityType';

type Error = string | null;
type Result = {
    isLoading: boolean;
    isError: boolean;
    data: Publication[] | undefined;
    error: Error;
};

export const usePublications = (
    storeId: string,
    setShopifyHasInstability: (newValue: boolean) => void
): Result => {
    return useQuery<Publication[], Error, Publication[]>(
        'publications',
        async (): Promise<Publication[]> => {
            return await apiFetch<Publications>(
                `${storeId}/configuration/retrieve-publications`,
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                }
            ).then((result) => {
                if (result.error === InstabilityType.shopify_instability) {
                    setShopifyHasInstability(true);
                }
                return result.publications;
            });
        }
    );
};
